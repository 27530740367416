
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import { app } from '@config';
import ButtonGroup from '../../../fruitpackage/components/ButtonGroup.vue';
import CopyUrl from '@part/elements/CopyUrl.vue';
import CardDefault from '@part/elements/CardDefault.vue';
import MuseumInfo from '@part/elements/MuseumInfo.vue';
import ListItem from '@part/elements/ListItem.vue';
import MyNightButton from '@part/elements/MyNightButton.vue';
import ArrowBackSvg from '@svg/ArrowBack.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import RefreshRedSvg from '@svg/RefreshRed.vue';
import ChainSvg from '@svg/Chain.vue';
import PinBoldSvg from '@svg/PinBold.vue';
import ClockBoldSvg from '@svg/ClockBold.vue';
import CalendarSvg from '@svg/Calendar.vue';
import AmbassadorsCard from '@part/elements/AmbassadorsCard.vue';
import DetailLayout from '@part/elements/DetailLayout.vue';
import LikeSvg from '@svg/like.vue';

import axios from 'axios';
import HighlightedAmbassadorsBlock from '@part/blocks/HighlightedAmbassadorsBlock.vue';
import OtherEvents from '@part/elements/OtherEvents.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    LikeSvg,
    DetailLayout,
    AmbassadorsCard,
    CalendarSvg,
    ListItem,
    MuseumInfo,
    HighlightedAmbassadorsBlock,
    CardDefault,
    MyNightButton,
    CopyUrl,
    ButtonGroup,
    ArrowBackSvg,
    ArrowForwardSvg,
    RefreshRedSvg,
    ChainSvg,
    PinBoldSvg,
    ClockBoldSvg,
    OtherEvents
  },
  data() {
    return {
      pageData: null,
      nearbyEvents: [],
      otherEventsList: [],
      backLink: {
        text: 'Onze routes',
        url: '/onze-routes'
      }
    };
  },
  props: {
    id: {
      required: true,
      default: 0
    }
  },
  methods: {
    sliderTitle(category) {
      return `Meer in ${category}`;
    }
  },
  mounted() {
    let $this = this;
    console.log('route', `${app.api.api}/curated-route/${$this.id}`);
    axios
      .get(`${app.api.api}/curated-route/${$this.id}`)
      .then(response => {
        console.log('response: ', response);
        $this.pageData = response.data.data;

        console.log($this.pageData.image);
        setTitle(response);
        axios
          .get(`${app.api.api}/feed/events/${$this.pageData.category_id}`)
          .then(resp => {
            $this.otherEventsList = resp.data.data;
          })
          .catch(error => {
            console.log(`🔥${error}🔥`);
            handle429(this, error);
          });

        axios
          .get(`${app.api.api}/feed/events-nearby/${$this.pageData.museum.location.id}`)
          .then(r => {
            $this.nearbyEvents = r.data.data;
          })
          .catch(error => {
            console.log(`🔥${error}🔥`);
            handle429(this, error);
          });
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class CuratedRouteDetail extends Vue {}
